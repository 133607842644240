<template>
    <div class="quotes">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
    }
</script>

<style>
.quotes {
    background-color: #fff;
}
</style>

